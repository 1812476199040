import React from 'react';
import {
  makeStyles, Grid,
  List,
  ListItem, IconButton, TextField, Container, Button, Input
} from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import { Helmet } from "react-helmet"
import { withPrefix, Link } from "gatsby"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import InstagramIcon from '@material-ui/icons/Instagram';
import PinterestIcon from '@material-ui/icons/Pinterest';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Services, Contact } from './components';


const useStyles = makeStyles((theme) => ({
  sectionAlternateNoPaddingTop: {
    '& .section-alternate__content': {
      paddingBottom: 0,
    },
  },
  image: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(10),

    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(5),
    },
    //marginBottom: theme.spacing(10),
    //width: 200,
    //height: 200,
  },
  legende: {
    paddingTop: 15,
    textAlign: "center",
    fontFamily: "Inconsolata",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    letterSpacing: "4px",
  },
  dividerSection: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  slider: {
    padding: 0,
    height: "700px"
  },
  concept: {
    padding: 0,
    paddingTop: 40,
  },
  gallery: {
    padding: 0,
    paddingBottom: 40,
  },
  titre: {
    fontFamily: "Montserrat",
    fontSize: "50px",
    fontWeight: 500,
    color: "#000000",
  },
  description: {
    paddingTop: 15,
    paddingBottom: 39,
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "27px",
    textAlign: "justify",
    textJustify: "inter-word",
  },
  soustitres: {
    //paddingTop: 15,
    fontFamily: "Inconsolata",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    letterSpacing: "4px",
  },
  coordonnees: {
    paddingTop: 45,
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "27px",
  },
  email: {
    paddingTop: 45,
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "27px",
  },
  contact: {
    fontFamily: "Montserrat",
    fontSize: "50px",
    fontWeight: 500,
    color: "#000000",
  },
  bouton: {
    boxSizing: "border-box",
    display: "inline-block",
    fontFamily: "Inconsolata,sans-serif",
    fontWeight: 400,
    letterSpacing: ".3em",
    lineHeight: "2em",
    margin: 50,
    outline: 0,
    position: "relative",
    textTransform: "uppercase",
    verticalAlign: "middle",
    width: "auto",
    fontSize: "14px",
    padding: "10px 83px",
    color: "#595959",
    backgroundColor: "transparent",
    border: "1px solid #595959",
    borderRadius: "0px",
  },
}));



const About = ({ data }) => {
  const classes = useStyles();
  const desktop = useMediaQuery('(min-width:600px)');
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Concept</title>
        <link rel="canonical" href={"https://elseink.com/about"} />

      </Helmet>
      <StaticQuery
        query={graphql`
      query {
        imgdesk: file(relativePath: { regex: "/studio_huangfanqi2.jpg/"}) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imgsmart: file(relativePath: { regex: "/studio_huangfanqi_smart.jpg/"}) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      `}
        render={data => (
          <div>
            <Section  >
              <Grid container >
                <Grid item xs={12} sm={8} md={6} >
                  <div className={classes.image}>
                    <Img
                      fluid={desktop ? data.imgdesk.childImageSharp.fluid : data.imgsmart.childImageSharp.fluid}

                    //lazyProps={{ width: '100%', height: '100%' }}
                    />
                    <Link to="/huangfangqi" className={classes.legende}>Studio Huang Fanqi</Link>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={6}>
                  <div className={classes.soustitres}>L’origine du concept</div>
                  <div className={classes.description}>« Else » pour l’ailleurs, et « ink » pour l’encre. Elseink habille vos intérieurs de son encre d’ailleurs. Que ce soit dans un lieu privé ou public, nous apportons cette touche qui évoque des émotions à la fois uniques et universelles.</div>
                  <div className={classes.soustitres}>Émotion</div>
                  <div className={classes.description}>Nous sommes une petite équipe, animée par l’idée d’entreprendre et guidée par l’amour de l’esthétique. Nous sommes persuadés qu’il n’y a pas de règles lorsqu’il s’agit de parler du beau. C’est une vérité accessible à tous car elle réside dans des expériences, des moments vécus. La beauté n’est pas élitiste, elle demande même de l’humilité. Pour vous, nous sélectionnons des tableaux d’exception, et ce, en collaboration directe avec chaque artiste.</div>
                  <div className={classes.soustitres}>Diffusion</div>
                  <div className={classes.description}>Nous avons à cœur de rester proches avec les artistes. Ils ont tous une histoire, une personnalité et des goûts différents. Ils partagent tous l’envie de communiquer et d’émouvoir le plus de personnes possibles. C’est la raison pour laquelle nous avons décidé, ensemble, de vous proposer des reproductions de chaque œuvre, en créant des impressions de haute qualité avec notre partenaire de confiance.</div>
                  <div className={classes.soustitres}>Durabilité</div>
                  <div className={classes.description}>Les papiers choisis font partie de la gamme naturelle proposée par Hahnemühle, en Allemagne, et sont garantis sans acide et sans azurant optique. Nous n’utilisons pas d’encre avec colorant, mais des encres pigmentaires dont la tenue à la lumière dépasse les 60 ans. Enfin, pour privilégier le circuit court, nous adoptons une démarche locale en faisant reproduire à Lyon en France.</div>
                </Grid>

              </Grid>
              <Services />

            </Section>


          </div>
        )}
      />








    </div>
  );
};



export default About;
