import React from 'react';
import {
  makeStyles, Grid,
  List,
  ListItem, IconButton, TextField, Container, Button, Input
} from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import { Helmet } from "react-helmet"



const useStyles = makeStyles(() => ({
  sectionAlternateNoPaddingTop: {
    '& .section-alternate__content': {
      paddingBottom: 0,
    },
  },
  dividerSection: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  slider: {
    padding: 0,
    height: "700px"
  },
  concept: {
    padding: 0,
    paddingTop: 40,
  },
  gallery: {
    padding: 0,
    paddingBottom: 40,
  },
  titre: {
    fontFamily: "Montserrat",
    fontSize: "50px",
    fontWeight: 500,
    color: "#000000",
  },
  description: {
    paddingTop: 15,
    paddingBottom: 35,
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "27px",
    textAlign: "justify",
    textJustify: "inter-word",
  },
  soustitres: {
    //paddingTop: 15,
    fontFamily: "Inconsolata",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    letterSpacing: "4px",
  },
  coordonnees: {
    paddingTop: 45,
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "27px",
  },
  email: {
    paddingTop: 45,
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "27px",
  },
  contact: {
    fontFamily: "Montserrat",
    fontSize: "50px",
    fontWeight: 500,
    color: "#000000",
  },
  bouton: {
    boxSizing: "border-box",
    display: "inline-block",
    fontFamily: "Inconsolata,sans-serif",
    fontWeight: 400,
    letterSpacing: ".3em",
    lineHeight: "2em",
    margin: 50,
    outline: 0,
    position: "relative",
    textTransform: "uppercase",
    verticalAlign: "middle",
    width: "auto",
    fontSize: "14px",
    padding: "10px 83px",
    color: "#595959",
    backgroundColor: "transparent",
    border: "1px solid #595959",
    borderRadius: "0px",
  },
}));



const Contact = ({ data }) => {
  const classes = useStyles();

  return (
    <div>


      <div className={classes.contact}>Contactez-nous</div>
      <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
        {/* You still need to add the hidden input with the form name to your JSX form */}
        <input type="hidden" name="form-name" value="contact" />

        <Container><TextField id="standard-basic" label="nom" name="Nom" /></Container>
        <Container><TextField id="standard-basic" label="email" name="Email" /></Container>
        <Container><TextField id="standard-basic" label="message" name="Message" /></Container>
        <Button

          fullWidth
          size="large"
          className={classes.bouton}
          type="submit"
        >
          Envoyer
                      </Button>
      </form>


    </div>
  );
};



export default Contact;
